import {withCookies, useCookies} from 'react-cookie';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/login/Login';
// import MainPage from './components/pages/MainPage';
import Main from './components/pages/Main';
import './App.css';
import { useEffect, useState } from 'react';

function App() {

  const [cookie, setCookie, removeCookie] = useCookies(['reftoken']);
  const [hasCookie, setHasCookie] = useState(false);

  useEffect(() => {
    if(cookie.reftoken && cookie.reftoken !== 'undefined'){
      setHasCookie(true);
    }else{
      setHasCookie(false);
    }
  },[cookie.reftoken]);

  return (
    <BrowserRouter>
            <Routes>
              <Route path='/' element={hasCookie?<Main/>:<Login/>}/>
              <Route path='/login' element={<Login/>}/>
              <Route path='*' element={<>Not found</>}/>
            </Routes>
        </BrowserRouter>
  );
}

export default App;
