import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Select, MenuItem, useTheme, IconButton } from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useCookies } from 'react-cookie';

export default function Hero() {
  const [cookie, setCookie, removeCookie] = useCookies(['name']);
  const themes = useTheme();
  const [SearchKeyword, setSearchKeyword] = React.useState("");

  const [selectedFile, setSelectedFile] = React.useState(null);
  const handleListItemClick = (event, name) => {
    setSelectedFile(name);
    name.likes?setLikes(name.likes.length):setLikes(0);
    name.likes?setIsLiked(name.likes.includes(cookie.id)):setIsLiked(false);
  };

  const handleCopyClipBoard = (code) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(code);
      alert('Copied to clipboard');
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      alert('Copied to clipboard');
    }
  }


  const [LangList, setLangList] = React.useState([]);
    React.useEffect(() => {
        fetch('/api/codebase', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                alert(res.error);
            } else {
                setLangList(res.result);
                setSelectLang(res.result[0]);
            }
        })
    }, []);

  const [CodeList, setCodeList] = React.useState([]);
  const [SelectCode, setSelectCode] = React.useState(null);
  React.useEffect(() => {
    selectedFile&&
    fetch('/api/code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({lang:selectedFile.lang, filename:selectedFile.filename}),
    })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        alert(res.error);
      } else {
        setSelectCode(res.result);
      }
    })
  }, [selectedFile]);

  const [SelectLang, setSelectLang] = React.useState("");

  const handleSearch = () => {
    fetch('/api/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({lang:SelectLang, keyword:SearchKeyword}),
    })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        alert(res.error);
      } else {
        setSelectedFile(null);
        setCodeList(res.result);
      }
    })
  }

  const [isLiked, setIsLiked] = React.useState(false);
  const [Likes, setLikes] = React.useState(0);
  const handleLike = () => {
    if(cookie.id){
      fetch('/api/like', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id:cookie.id, filename:selectedFile.filename}),
      })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          alert(res.error);
        } else {
          setIsLiked(!isLiked);
          setLikes(res.likes.length);
        }
      })
    }else{
      alert('Please Login');
    }
  }

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        // width: '100%',
        width: '100vw',
        // height: '100vh',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          // height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 12, sm: 15 },
          pb: { xs: 6, sm: 10 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: { xs: '3.5rem', sm: '4rem', md: '5rem' },
              fontFamily: 'serif',
            }}
          >
            Code Finder
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{
              pt: 2, width: { xs: '100%', sm: 'auto'},
              display: 'flex',
              alignItems: 'center',

            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className='select-lang'
              value={SelectLang||""}
              label="Language"
              onChange={(e) => setSelectLang(e.target.value)}
              sx={{ minWidth: 100, fontFamily:'serif' }}
            >
              {LangList.map((lang, index) => (
                <MenuItem value={lang} key={index}>{lang}</MenuItem>
              ))}
            </Select>
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Example: interpolation, 2d"
              placeholder="Example: interpolation, 2d"
              inputProps={{
                autocomplete: 'off',
                ariaLabel: 'Example: interpolation, 2d',
              }}
              onChange={(e) => setSearchKeyword(e.target.value)}
              sx={{fontFamily:'serif'}}
            />
            <Button variant="contained" color="primary" sx={{fontSize:'1.6rem', fontFamily:'BasicB'}} onClick={(e)=>handleSearch()}>
              Search
            </Button>
          </Stack>
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: '70vh',
            width: '98vw',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
            padding: '1rem',
          })}
        >
          <div style={{
            display: 'flex',
            height: '100%',
          }}>
            <Paper
              sx={{
                bgcolor: 'background.paper',
                borderRadius: '10px',
                boxShadow: 1,
                width: '25rem',
                height: '100%',
                padding: '1rem',
                overflowX: 'auto',
                overflowY: 'auto',
                fontFamily: 'serif',
              }}>
                <List component="nav" aria-label="main mailbox folders">
                  {CodeList.length!==0?CodeList.map((code, index) => (
                    <ListItemButton
                      selected={selectedFile?selectedFile.filename === code.filename:false}
                      onClick={(event) => handleListItemClick(event, code)}
                      key={index}
                    >
                      <ListItemText primary={code.filename} />
                    </ListItemButton>
                  )):<p>No Data</p>}
                </List>
              </Paper>
            <div className='code-view-container'
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex:2,
                height: '100%',
                marginLeft: '0.5rem',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '4rem',
                  fontFamily: 'serif',
                }}
              >
                <Typography style={{float:'left', marginRight:'1rem', fontFamily:'serif'}}>
                  last updated: {selectedFile?selectedFile.lastmdd:null}
                </Typography>
                {selectedFile?
                <IconButton style={{float:'right'}} onClick={()=>handleLike()}>
                  {isLiked?<FavoriteIcon sx={{mr:'0.5rem'}}/>:<FavoriteBorderIcon sx={{mr:'0.5rem'}}/>}
                  {Likes} 
                </IconButton>:null}
              </div>
              <Paper
                className='code-summary'
                sx={{
                  bgcolor: 'background.paper',
                  borderRadius: '10px',
                  boxShadow: 1,
                  width: '100%',
                  height: '24rem',
                  padding: '1rem',
                  marginBottom: '0.5rem',
                }}
              >
                {selectedFile?
                <SyntaxHighlighter
                  language="plaintext"
                  style={{
                    ...themes.palette.mode === 'light' ? a11yLight : a11yDark,
                  }}
                >
                  {selectedFile.summary} 
                </SyntaxHighlighter>:null
              }
              </Paper>
              <Paper
                className='code-view'
                sx={{
                  bgcolor: 'background.paper',
                  borderRadius: '10px',
                  boxShadow: 1,
                  width: '100%',
                  flex:1,
                  overflow: 'hidden',
                  padding: '1rem',
                  paddingBottom:'2rem',
                  position: 'relative',
                }}>
                  {SelectCode?<IconButton className='copy-icon' onClick={() => {handleCopyClipBoard(SelectCode)}}>
                    <ContentCopyRoundedIcon />
                  </IconButton>:null}
                  {selectedFile?
                  <SyntaxHighlighter 
                    language={selectedFile.lang} 
                    style={{
                      ...themes.palette.mode === 'light' ? a11yLight : a11yDark,
                      height: '100%',
                  }}
                  >
                    {SelectCode}  
                  </SyntaxHighlighter>:null
                }
                </Paper>
            </div>
          </div>
        </Box>
      </Container>
    </Box>
  );
}