import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ToggleColorMode from './ToggleColorMode';
import { useCookies } from 'react-cookie';


function AppAppBar({ mode, toggleColorMode, setMain }) {
  const [open, setOpen] = React.useState(false);
  const [cookie, setCookie, removeCookie] = useCookies(['name']);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const handleMain = (page)=>{
    page === 'main'? setMain(0):
      page === 'upload'? setMain(1):setMain(2);
  }
  const handlelogout = (event) =>{
    removeCookie('name');
    removeCookie('reftoken');
    removeCookie('id');
    fetch('/api/auth/logout', {
        method: 'GET',
        credentials: 'include',
    })
    .then((res) => {
        if(res.redirected){
            return window.location.replace(res.url);
        }
    })
    .catch((err) => {
        alert(`로그아웃에 실패했습니다. ${err}`);
    });
  }

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <img
                className='logo-img'
                src="imgs/logo.png"
                alt="logo of sitemark"
              />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <MenuItem
                  onClick={() => handleMain('main')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary" style={{
                    fontSize: '1.8rem',
                    fontFamily:'BasicEB'
                  }}>
                    <b>Home</b>
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleMain('upload')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary" style={{
                    fontSize: '1.8rem',
                    fontFamily:'BasicEB'
                  }}>
                    <b>Upload</b>
                  </Typography>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => handleMain('faq')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary"style={{
                    fontSize: '1.8rem',
                    fontFamily:'BasicEB'
                  }}>
                    <b>FAQ</b>
                  </Typography>
                </MenuItem> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
              <Button
                color="primary"
                variant="contained"
                size="small"
                component="a"
                onClick={(e)=>handlelogout(e)}
                sx={{ fontSize: '1.4rem', fontFamily: 'BasicB'}}
              >
                Logout
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;