import { Avatar, Button, CssBaseline, TextField, Paper, Box, Grid, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function Login(){

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const id = data.get('id');
        const password = data.get('password');
        if (id === '' || password === '') {
          alert('아이디와 비밀번호를 입력해주세요.');
        }
        else {
          fetch(`/api/auth/login`, {  // 로그인 요청
            method: 'POST',
            
            headers: {
              'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                id: id,
                password: password,
              })
            })
                .then((res) => res.json())
                .then((res) => {
                  if (res.result === 'success') {
                    console.log(res.token);
                    window.location.href = '/';
                  } else {
                      alert(`로그인 실패 : ${res.error}`);
                  }
                      })
        };
      };
    

    return(
        <Grid container component="main" sx={{ height: '100vh', width:'100vw' }}>
            <CssBaseline />
            {/* Left Image */}
            <Grid
                item
                xs={false}
                sm={7}
                md={9}
                sx={{
                    backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}>
                <div className="login-header">
                    <b>GeoSR </b>
                    <span>Code-Finder</span>
                </div>
            </Grid>

            {/* Right Login Form */}
            <Grid item xs={12} sm={5} md={3} component={Paper} elevation={6} square>
                <Box
                    sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop:'50%'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'lightgray' }}>
                    <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" style={{fontSize:'2.5rem'}}>
                    Sign in
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        className='login-input'
                        margin="normal"
                        required
                        fullWidth
                        id="id"
                        label="ID"
                        name="id"
                        autoComplete="id"
                        autoFocus
                    />
                    <TextField
                        className='login-input'
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    
                    <Button
                        className='signin-btn'
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, fontSize:'1.5rem' }}
                    >
                        Sign in
                    </Button>
                    {/* <Grid container> */}
                        <Grid item>
                        </Grid>
                    {/* </Grid> */}
                    </Box>
                </Box>
            </Grid>
        </Grid>

    )
}