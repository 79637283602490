import * as React from 'react';
import { Box,alpha,Stack,Typography,Container,Button,TextField } from '@mui/material';

export default function Upload() {
    const [con, setCon] = React.useState(false);
    const [sumRows, setSumRows] = React.useState(3);
    const [codeRows, setCodeRows] = React.useState(10);

    React.useEffect(() => {
        function handleResize() {
            const windowHeight = window.innerHeight;
            let newCRows = Math.floor(windowHeight / 38);
            let newSRows = Math.floor(windowHeight / 70);
            if(windowHeight<1150) newSRows = Math.floor(windowHeight / 90);
            if(windowHeight<990) newSRows = 5;
            if(windowHeight<830) newSRows = 3;
            if(windowHeight<780) newSRows = 2;
            setCodeRows(newCRows);
            setSumRows(newSRows);
        }
    
        window.addEventListener('resize', handleResize);
        handleResize(); // 컴포넌트가 마운트될 때 초기 한 번 실행
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const handleUpload = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get('file-name') === '' || data.get('language') === '' || data.get('code') === ''){
            alert('File Name, Language, and Code are required!');
            return;
        }
        const datas = {
            file: data.get('file-name'),
            language: data.get('language'),
            keyword: data.get('keyword'),
            dependency: data.get('dependency'),
            summary: data.get('summary'),
            code: data.get('code'),
            conf: con,
        };
        fetch('/api/upload', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(datas),
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                alert(res.error);
            } else if (res.exist) {
                if (window.confirm('동일한 이름의 파일이 존재합니다. 덮어씌울 시 기존 데이터는 삭제됩니다. 계속하시겠습니까?')) {
                    datas.conf = true;
                    fetch('/api/upload', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(datas),
                    })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            alert(res.error);
                        } else {
                            alert('Upload Success!');
                            window.location.reload();
                        }
                    });
                }
            }
            else {
                alert('Upload Success!');
                window.location.reload();
            }
        })
    }

    return (
        <Box
        id="upload"
        sx={(theme) => ({
            width: '100%',
            backgroundImage:
            theme.palette.mode === 'light'
                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
            backgroundSize: '100% 20%',
            backgroundRepeat: 'no-repeat',
        })}
        >
            <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pt: { xs: 14, sm: 20 },
                pb: { xs: 8, sm: 12 },
            }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                    <Typography
                    component="h1"
                    variant="h1"
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignSelf: 'center',
                        textAlign: 'center',
                        fontFamily: 'serif',
                    }}
                    >
                    Upload Code
                    </Typography>
                </Stack>
                <Box
                    id="image"
                    component="form"
                    noValidate
                    onSubmit={handleUpload}
                    sx={(theme) => ({
                        mt: { xs: 8, sm: 10 },
                        alignSelf: 'center',
                        height: '73vh',
                        minHeight:'530px',
                        width: '100%',
                        backgroundImage:
                        theme.palette.mode === 'light'
                            ? 'url("/static/images/templates/templates-images/hero-light.png")'
                            : 'url("/static/images/templates/templates-images/hero-dark.png")',
                        backgroundSize: 'cover',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                        theme.palette.mode === 'light'
                            ? alpha('#BFCCD9', 0.5)
                            : alpha('#9CCCFC', 0.1),
                        boxShadow:
                        theme.palette.mode === 'light'
                            ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                            : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                        padding: '4rem',
                        display: 'flex',
                    })}
                >
                    <div style={{height:'100%', display:'flex', flexDirection:'column'}}>
                    <div className='up-div'><Typography variant="h3" sx={{ color: 'text.primary', fontSize: '2.5rem',  marginBottom: '0.5rem' }}>
                        File Name
                    </Typography>
                    <TextField
                        id="fine-name"
                        name="file-name"
                        required
                        hiddenLabel
                        size="small"
                        variant="outlined"
                        aria-label="Example: file_name.py"
                        placeholder="Example: file_name.py"
                        inputProps={{
                            autocomplete: 'off',
                            ariaLabel: 'File Name',
                        }}
                    /></div>
                    <div className='up-div'><Typography variant="h3" sx={{ color: 'text.primary', fontSize: '2.5rem',  marginBottom: '0.5rem' }}>
                        Language
                    </Typography>
                    <TextField
                        id="language"
                        name="language"
                        required
                        hiddenLabel
                        size="small"
                        variant="outlined"
                        aria-label="Example: python"
                        placeholder="Example: python"
                        inputProps={{
                            autocomplete: 'off',
                            ariaLabel: 'Language',
                        }}
                    /></div>
                    <div className='up-div'><Typography variant="h3" sx={{ color: 'text.primary', fontSize: '2.5rem',  marginBottom: '0.5rem' }}>
                        Keyword
                    </Typography>
                    <TextField
                        id="keyword"
                        name="keyword"
                        hiddenLabel
                        size="small"
                        variant="outlined"
                        aria-label="Example: 2D, interpolation"
                        placeholder="Example: 2D, interpolation"
                        inputProps={{
                            autocomplete: 'off',
                            ariaLabel: 'Keyword',
                        }}
                    /></div>
                    <div className='up-div'><Typography variant="h3" sx={{ color: 'text.primary', fontSize: '2.5rem',  marginBottom: '0.5rem' }}>
                        Dependency
                    </Typography>
                    <TextField
                        id="dependency"
                        name="dependency"
                        hiddenLabel
                        size="small"
                        variant="outlined"
                        aria-label="Example: numpy, pandas"
                        placeholder="Example: numpy, pandas"
                        inputProps={{
                            autocomplete: 'off',
                            ariaLabel: 'Dependency',
                        }}
                    /></div>
                    <div className='up-div' style={{flex:1}}><Typography variant="h3" sx={{ color: 'text.primary', fontSize: '2.5rem',  marginBottom: '0.5rem' }}>
                        Summary
                    </Typography>
                    <TextField
                        id="summary"
                        name="summary"
                        hiddenLabel
                        multiline
                        size="small"
                        variant="outlined"
                        aria-label="Example: Interpolation using numpy..."
                        placeholder="Example: Interpolation using numpy..."
                        inputProps={{
                            autocomplete: 'off',
                            ariaLabel: 'Summary',
                        }}
                        maxRows={sumRows}
                        sx={{height:'100%'}}
                    /></div>
                    </div>
                    <div style={{ marginLeft:'5rem', display:'flex', flexDirection:'column', flex:1}}>
                    <div className='up-div' style={{flex:1}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Typography variant="h3" sx={{ color: 'text.primary', fontSize: '2.5rem',  marginBottom: '0.5rem', justifyContent: 'space-between' }}>
                        Code
                    </Typography>
                    <Button variant="contained" type='submit' color="primary" sx={{fontSize:'1rem',marginTop:0, fontSize:'1.5rem',fontFamily:'BasicB', height: '3rem'}}>
                        <b>Upload</b>
                    </Button>
                    </div>
                    <TextField
                        id="code"
                        name="code"
                        required
                        hiddenLabel
                        multiline
                        fullWidth
                        size="small"
                        variant="outlined"
                        aria-label="Input your code here..."
                        placeholder="Input your code here..."
                        inputProps={{
                            autocomplete: 'off',
                            ariaLabel: 'Code',
                        }}
                        maxRows={codeRows}
                        sx={{height:'100%'}}
                    /></div>
                    
                    </div>

                </Box>
            </Container>

        </Box>
    );
}